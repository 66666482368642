import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ACTIONS_VIDEO, IAllReconstructionJobs, IMAGE_LIST_TYPE, IReconstructJob, IReconstructJobOptions, IReconstructJobUI, RECONSTRUCTION_ACTION } from '../generate';
import { GeneratedVideosService } from '../generated-videos.service';
import { UtilsService } from '../../shared/utils.service';
import { GraphqlService } from '../../communication/graphql.service';
import { ScrollHelper } from '../../shared/scroll-helper';
import { ApolloQueryResult } from '@apollo/client';
import { imageCardDelay } from '../../shared/constants';
import { CommonModule } from '@angular/common';
import { GeneratedVideoCardComponent } from '../generated-video-card/generated-video-card.component';
import { Subscription } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { BroadcasterService } from 'ng-broadcaster';
import { BodyService } from '../../shared/body.service';

@Component({
  selector: 'app-generated-videos',
  imports: [GeneratedVideoCardComponent, CommonModule],
  templateUrl: './generated-videos.component.html',
  styleUrl: './generated-videos.component.scss'
})
export class GeneratedVideosComponent implements OnDestroy {
  @Input() current: IReconstructJobUI;
  @Input('type') type: IMAGE_LIST_TYPE;
  @Input('actions') actions: Array<RECONSTRUCTION_ACTION>;
  public IMAGE_LIST_TYPE = IMAGE_LIST_TYPE;
  public hasItems: boolean;
  public isLoggedIn: boolean;
  @ViewChild('scroll') public scroll: ElementRef;
  @Output() onSelect: EventEmitter<IReconstructJobUI>;
  private scrollHelper: ScrollHelper;
  private _subs: Array<Subscription>;
  constructor(
    public giService: GeneratedVideosService,
    private utils: UtilsService,
    private gql: GraphqlService,
    private auth: AuthService,
    private broadcaster: BroadcasterService,
    private body: BodyService
  ) {
    this.onSelect = new EventEmitter<IReconstructJobUI>();
    this.hasItems = false;
    this._subs = [];
  }

  async ngOnInit() {
    if (await this.auth.isloggedIn())
      await this.initIfLoggedin();
    else
      this._subs.push(this.broadcaster.on('onLogin').subscribe(this.initIfLoggedin.bind(this)));
  }

  async initIfLoggedin() {
    this.isLoggedIn = true;
    const items = await this.utils.observableToPromise(
      this.gql.allReconstructionJobs({
        limit: GeneratedVideosService.DEFAULT_FILTER.limit,
        offset: GeneratedVideosService.DEFAULT_FILTER.offset,
        action_id: this.actions || ACTIONS_VIDEO
      } as IReconstructJobOptions)
    ) as ApolloQueryResult<IAllReconstructionJobs>;
    this.giService.items = this.utils.deepCopyByValue(items.data.allReconstructionJobs.rows) as Array<IReconstructJob>;
    this.giService.itemsCount = items.data.allReconstructionJobs.count;
    for (let i = 0; i < this.giService.items.length; i++) {
      (this.giService.items[i] as IReconstructJobUI)._delayEnter = (i * imageCardDelay);
    }
    await this.utils.setTimeout();
    this.initScrollHelper();
    this._subs.push(
      this.broadcaster.on('onGenerating').subscribe((data: any) => {
        this.onPublish(data);
      })
    );
  }

  initScrollHelper() {
    if (!this.scroll?.nativeElement)
      this.scroll = new ElementRef(document.getElementById('creation-images-list'));
    if (this.scrollHelper) this.scrollHelper.destroy();
    if (this.scroll.nativeElement) {
      this.scrollHelper = new ScrollHelper(this.scroll.nativeElement);
      this.scrollHelper.maxPercentage = 0.6;
      // this.scrollHelper.hirizontalThreshold = mobileThreshold;
      this.scrollHelper.onScrollThreshold.subscribe(this.fetchMore.bind(this));
    }
  }

  async onPublish(data: IReconstructJobUI) {
    if (ACTIONS_VIDEO.find(a => a === data.action_id)) {
      data._delayEnter = 1;
      this.addNewItem(data);
      setTimeout(() => {
        const j = document.getElementById(`job_${data.id}`);
        if (j) this.body.scrollToElement(new ElementRef(j));
      });
    }
  }

  addNewItem(item: IReconstructJob) {
    if (!this.giService.items.find(i => i.id === item.id)) {
      this.giService.items.unshift(item);
      this.giService.itemsCount++;
      setTimeout(() => {
        if (!this.scroll?.nativeElement) this.initScrollHelper();
      });
    }
  }

  fetchMore() {
    if (typeof this.giService.itemsCount === 'number') {
      if (
        this.giService.itemsCount <= this.giService.items.length
      )
        return;
    } else return;
    this.giService.scrollOffset = this.giService.items.length;
    this.giService.searchByQuery();
  }

  setImage(item: IReconstructJobUI) {
    this.current = item;
    this.onSelect.next(item);
  }

  renderedItem() {
    this.hasItems = true;
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }
}
