import { Component, Input } from '@angular/core';
import { SrcSuffixPipe } from '../../shared/src-suffix.pipe';
import { CommonModule } from '@angular/common';
import { IReconstructJobUI } from '../generate';
import { UtilsService } from '../../shared/utils.service';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-generated-video-card',
  imports: [SrcSuffixPipe, CommonModule, MatTooltipModule],
  templateUrl: './generated-video-card.component.html',
  styleUrl: './generated-video-card.component.scss'
})
export class GeneratedVideoCardComponent {
  @Input() item: IReconstructJobUI;
  public init: boolean;
  public loaded: boolean;
  constructor(private utils: UtilsService) { }

  ngOnInit() {
    if (typeof this.item._delayEnter !== 'number') {
      this.init = true;
      this.loaded = true;
    } else setTimeout(() => (this.loaded = true), this.item._delayEnter);
  }

  async ngAfterViewInit() {
    await this.utils.setTimeout();
    this.init = true;
  }

  getTT() {
    if (this.item.reconstruction_jobs_inputs?.length)
      return this.item.reconstruction_jobs_inputs[0].text_input;
    return null;
  }
}
